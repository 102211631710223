.loading { 
  padding:10px;
  border-radius: 8px;
  text-align: center;
  position: relative;  
  animation: maskBorder 1s infinite;
}

/* .text { top: 7px; }
.border  { 
    z-index: 5; border-radius: 10px;
    display: block; position: absolute; 
    left: 4px; top: 4px; bottom: 4px; right: 4px; display: block; }

.text { top: 7px; }
.border { 
  
  border: 2px dashed blue;
  clip: rect(0,0,0,0); 
  
  animation: maskBorder 1.4s infinite;
  
}*/

.modal {
  bottom: 0;
  left: 0;
  margin: auto;
  max-height: 500px;
  max-width: 600px;
  min-width: 300px;
  position: fixed;
  right: 0;
  top: 0;
}




@keyframes maskBorder {
  
  0% { background: #c4c4c4; }
  50% { background: #a1a1a1; }
  100% { background: #c4c4c4;  }
} 


  .footer {

    background: #eee;
    top: 0px;
    left:0px;
    right:0px;
    bottom:0;
    height: 60px; 
    overflow: hidden;
    position: absolute;
  }

  .user1 {
    float: left;
    background-color: #fff;
    width: 60%;
    max-width: 250px;
    clear: both;
    padding: 5px;
    border-radius: 0px 8px 8px 8px;
    margin: 0 5px 20px;
  }

  .audio {
    float: left;
    background-color: #fff;
    width: 320px;
    clear: both;
    padding: 5px;
    border-radius: 0px 8px 8px 8px;
    margin: 0 5px 20px;
  }

  .user0 {
    float: right;
    background-color: #dcf8c6;
    padding: 5px;
    max-width: 250px;
    clear: both;
    border-radius: 8px 0px 8px 8px;
    margin: 10px 5px 20px;
  }

  .usertext0 {
    margin:0 20px 10px 10px;
  }
  .usertext1 {
    margin:0 10px 10px 10px;
  }

  .time {
    font-size: 10px;
    float: right;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: rgb(180, 180, 180);
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .toast {
     width: 100%;
     height: 60px;
     background: #40b876;
     position: absolute;
     bottom: 0;
     margin-bottom: 60px;
     color: white;
     font-size: 18px;
     text-align: center;
     font-weight: bolder;
  }

  .toast_message {
    margin-top: 18px;
  }