.down_button {
    padding: 15px;
    display: inline-block;
    width: 80px;
    height: 80px; 
    border-radius: 12px;
    box-shadow: 1px 1px 14px 1px rgb(190, 190, 190);
    background: rgb(184, 31, 31);
    color: white;
    margin: 10px;
    text-align: center;
    font-size: 22px;
}

.rec_duration {
    font-size: 18px;
    font-weight: 500;
}

.up_button {
    padding: 15px;
    display: inline-block;
    width: 80px;
    height: 80px; 
    border-radius: 12px;
    box-shadow: 1px 1px 14px 1px rgb(190, 190, 190);
    background: #fff;
    color: red;
    margin: 10px;
    font-size: 22px;
}

.record_text {
    margin: 0;
    font-weight: 800;
    font-size: 14px;
}

.send_btn {
    padding: 15px;
    width: 80px;
    height: 80px; 
    border-radius: 12px;
    box-shadow: 1px 1px 14px 1px rgb(190, 190, 190);
    background: #075E54;
    color: white;
    float: right;
    margin: 10px;
    font-size: 20px;
}

.play_btn {
    padding: 15px;
    display: inline-block;
    width: 80px;
    height: 80px; 
    border-radius: 12px;
    box-shadow: 1px 1px 14px 1px rgb(190, 190, 190);
    background: #18682c;
    color: white;
    margin: 10px;
    font-size: 20px;
}

.pause_btn {
    padding: 15px;
    display: inline-block;
    width: 80px;
    height: 80px; 
    border-radius: 12px;
    box-shadow: 1px 1px 14px 1px rgb(190, 190, 190);
    background: #fff;
    color: #18682c;
    margin: 10px;
    font-size: 20px;
}

.audio_del_btn {
    padding: 15px;
    display: inline-block;
    width: 80px;
    height: 80px; 
    border-radius: 12px;
    box-shadow: 1px 1px 14px 1px rgb(190, 190, 190);
    background: #201010;
    color: white;
    margin: 10px;
    font-size: 20px;
}

.success_text {
    font-size: 34px;
}